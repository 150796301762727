import { useState, useEffect } from 'react'

const useForm = (initialValues, callback, validate) => {
  const [values, setValues] = useState(initialValues)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const initialState = {
    email: '',
    first_name: '',
    last_name: '',
    practice_name: '',
    specialty: '',
    street_address: '',
    street_address2: '',
    city: '',
    phone: '',
    zip: '',
    state: '',
  }

  const resetForm = () => {
    setValues({ ...initialState })
  }

  const handleSubmit = event => {
    if (event) event.preventDefault()
    setIsSubmitting(true)
    setErrors(validate(values))
  }

  const handleChange = event => {
    event.persist()

    setValues(values => ({
      ...values,
      [event.target.name]: event.target.value,
    }))
  }

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      callback()
    }
  }, [errors])

  return {
    handleChange,
    handleSubmit,
    values,
    errors,
    resetForm,
  }
}

export default useForm
