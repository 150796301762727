import React from 'react'
import Modal from 'react-awesome-modal'

/*
const OpenLink = (closeAction, openURL) => {
  closeAction()
  setTimeout(() => {
    window.open(openURL)
  }, 100)
}
*/

const Modali = ({ isVisible, children }) => (
  <Modal visible={isVisible}>
    <div className="modal-container">{children}</div>
  </Modal>
)

export default Modali
