import React, { useState } from "react";
import { Row, Col } from "react-flexbox-grid";
import { InputGroup, Label, Card, Elevation, Intent } from "@blueprintjs/core";
import axios from "axios";
import Modali from "../general/modali";
import useForm from "./useForm";
import validate from "./FormValidation";
import { US_STATES } from "../../lib/util.helper";
import "./request.scss";

const specialtiesList = [
  "Primary Care Physician",
  "Pain Specialist",
  "Orthopedics",
  "Other",
];

// const DFA_API_SERVICES_URL = process.env.DFA_API_SERVICES_URL;
// const DFA_API_SERVICES_URL = "http://172.33.2.140:3000/api/email/request-rep";
const DFA_API_SERVICES_URL =
  "https://8cnxfbtjla.execute-api.us-east-1.amazonaws.com/assertioprod/req-rep-email";

const RequestRepForm = ({ className }) => {
  const { values, handleChange, handleSubmit, errors, resetForm } = useForm(
    {
      brand: "ZIPSOR",
      email: "",
      first_name: "",
      last_name: "",
      practice_name: "",
      specialty: "",
      street_address: "",
      street_address2: "",
      city: "",
      phone: "",
      zip: "",
      state: "",
    },
    onSubmit,
    validate
  );
  const [openModal, setOpenModal] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  let dataToSend = {};
  function onSubmit() {
    dataToSend.data = values;

    //Pass these values to the API
    console.log(dataToSend);
    //
    setInProgress(true);
    axios
      .post(DFA_API_SERVICES_URL, dataToSend)
      .then((response) => {
        setOpenModal(true);
        resetForm();
        setTimeout(() => {
          setInProgress(false);
        }, 100);
      })
      .catch((error) => {
        resetForm();
        setTimeout(() => {
          setInProgress(false);
        }, 100);
        console.log(error);
      });
  }
  return (
    <>
      <form onSubmit={handleSubmit} noValidate>
        <Card
          interactive={true}
          elevation={Elevation.TWO}
          className={`card-request-a-rep ${className}`}
        >
          <div className="control-group">
            <Label className="lbl-firstname">First Name*</Label>
            <InputGroup
              name="first_name"
              large={true}
              type="text"
              className="input-control"
              maxLength="50"
              intent={errors.first_name && Intent.DANGER}
              onChange={handleChange}
              value={values.first_name}
            />
            {errors.first_name && (
              <p className="info is-danger">{errors.first_name}</p>
            )}
          </div>
          <div className="control-group">
            <Label className="lbl-lastname">Last Name*</Label>
            <InputGroup
              name="last_name"
              large={true}
              type="text"
              className="input-control"
              maxLength="50"
              intent={errors.last_name && Intent.DANGER}
              onChange={handleChange}
              value={values.last_name}
            />
            {errors.last_name && (
              <p className="info is-danger">{errors.last_name}</p>
            )}
          </div>
          <div className="control-group">
            <Label className="lbl-practice-name">Practice Name*</Label>
            <InputGroup
              name="practice_name"
              large={true}
              type="text"
              className="input-control"
              maxLength="200"
              intent={errors.practice_name && Intent.DANGER}
              onChange={handleChange}
              value={values.practice_name}
            />
            {errors.practice_name && (
              <p className="info is-danger">{errors.practice_name}</p>
            )}
          </div>
          <div className="control-group">
            <Label className="lbl-practice-name">Specialty</Label>
            <div className="bp3-select bp3-large bp3-fill">
              <select
                id="select-specialty"
                name="specialty"
                onChange={handleChange}
                value={values.specialty}
              >
                <option defaultValue value="">
                  Select
                </option>
                {specialtiesList &&
                  specialtiesList.map((item) => {
                    return (
                      <option value={item} key={item}>
                        {item && item}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
          <div className="control-group">
            <Label className="lbl-email">Email Address*</Label>
            <InputGroup
              large={true}
              type="email"
              name="email"
              className="input-control"
              maxLength="50"
              intent={errors.email && Intent.DANGER}
              onChange={handleChange}
              value={values.email}
            />
            {errors.email && <p className="info is-danger">{errors.email}</p>}
          </div>
          <div className="control-group">
            <Label className="lbl-address1">Address 1</Label>
            <InputGroup
              name="street_address"
              large={true}
              type="text"
              className="input-control"
              maxLength="100"
              onChange={handleChange}
              value={values.street_address}
            />
          </div>
          <div className="control-group">
            <Label className="lbl-address2">Address 2 (optional)</Label>
            <InputGroup
              name="street_address2"
              large={true}
              type="text"
              className="input-control"
              maxLength="100"
              onChange={handleChange}
              value={values.street_address2}
            />
          </div>
          <div className="control-group">
            <Label className="lbl-city">City</Label>
            <InputGroup
              name="city"
              large={true}
              type="text"
              className="input-control"
              maxLength="50"
              onChange={handleChange}
              value={values.city}
            />
          </div>
          <div className="control-group">
            <Label className="lbl-state">State*</Label>
            <div
              className={`bp3-select bp3-large bp3-fill ${
                errors.state && "border-red"
              }`}
            >
              <select
                id="select-state"
                name="state"
                onChange={handleChange}
                value={values.state}
              >
                <option defaultValue>Select</option>
                {US_STATES &&
                  US_STATES.map((item) => {
                    return (
                      <option value={item.abbreviation} key={item.abbreviation}>
                        {item.name}
                      </option>
                    );
                  })}
              </select>
            </div>
            {errors.state && <p className="info is-danger">{errors.state}</p>}
          </div>
          <div className="control-group">
            <Label className="lbl-zip">ZIP Code</Label>
            <InputGroup
              name="zip"
              large={true}
              type="text"
              className="input-control"
              maxLength="5"
              onChange={handleChange}
              value={values.zip}
            />
          </div>
          <div className="control-group">
            <Label className="lbl-phone">Phone Number</Label>
            <InputGroup
              name="phone"
              large={true}
              type="tel"
              className="input-control"
              maxLength="15"
              onChange={handleChange}
              value={values.phone}
            />
          </div>
          <Row>
            <Col xs={12}>
              <button
                type="submit"
                className="cta-button purple btn-send"
                disabled={inProgress}
              >
                SEND
              </button>{" "}
              {inProgress && <span style={{ color: "gray" }}>Sending...</span>}
              <p className="privacy-text">
                Privacy:
                <br />
                Assertio respects your right to have your personal information
                kept private. We may use this information to send you materials
                on our products and services that may be of interest to you. We
                will not share your information with any third parties or
                outside mailing lists. By submitting this registration, I
                understand that I am giving Assertio Therapeutics and its
                affiliates and business partners permission to use my personal
                information to provide me with information and offers related to
                support programs, treatment options, and services provided by
                Assertio. I understand that Assertio and its contractors will
                not sell or rent my personal information.
              </p>
              <p className="privacy-text">
                For more information about how Assertio will use and protect
                your personal information, please read the{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.assertiotx.com/privacy-policy/"
                  className="text-underline text-orange"
                >
                  Privacy Policy
                </a>
                .
              </p>
            </Col>
          </Row>
        </Card>
      </form>
      <Modali isVisible={openModal}>
        <div className="interstitial-modal">
          <Row>
            <Col xs={12}>
              <p style={{ fontSize: 30 }}>Thank you for your request.</p>
            </Col>
          </Row>
          <Row center={"xs"}>
            <Col xs={12}>
              <button onClick={() => setOpenModal(!openModal)}>Ok</button>
            </Col>
          </Row>
        </div>
      </Modali>
    </>
  );
};

export default RequestRepForm;
