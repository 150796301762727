/**
 *
 * HCP Index Page
 *
 */
import React from 'react'
import { Row, Col } from 'react-flexbox-grid'
import Layout from '../../components/dfa-theme/layout/hcp'
import myEmitter from '../../lib/emitter'
import imgDownloadCopay from '../../images/hcp-landing-download-copay.png'
import imgNowAvailable from '../../images/hcp-landing-now-available.png'
import imgRequestRep from '../../images/hcp-landing-request-rep.png'
import imgCopayBox from '../../images/copay_img.png'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import PageIndicator from '../../components/page-indicator/hcp/get-in-touch'

import RequestRepForm from '../../components/request-a-rep-form'

import '@blueprintjs/icons/lib/css/blueprint-icons.css'
import '@blueprintjs/core/lib/css/blueprint.css'
import './get-in-touch.scss'

let el_top = 0
let el_bottom = 0
let el = null
let elHeight = 0
let elOffset = 0
let winHeight = 0
let elHome = ''
let elHeader = ''
let ACTIVE_MENU_ON_SCROLL = null //onscroll

const scrollSections = [
  'helpful-resources',
  'download-copay-card',
  'prescribe-to-scripts-rx',
  'request-a-rep-form',
]

class IndexPage extends React.Component {
  state = {
    activesection: 'helpful-resources',
  }

  throttled = (delay, fn) => {
    let lastCall = 0
    return function(...args) {
      const now = new Date().getTime()
      if (now - lastCall < delay) {
        return
      }
      lastCall = now
      return fn(...args)
    }
  }

  handleScroll = () => {
    scrollSections.map(item => {
      el = document.getElementById(item)
      elHeight = el.clientHeight
      elOffset = elHeight * 0.7
      el_top = el.getBoundingClientRect().top
      el_bottom = el.getBoundingClientRect().bottom - elOffset
      if (el_top <= elHeight && el_bottom <= winHeight) {
        myEmitter.emit('UPDATE_ACTIVE_SECTION_ONSCROLL', item)
      }
    })
  }

  scrollListener = () => {
    this.throttled(500, this.handleScroll())
    if (elHeader.clientHeight === elHome.getBoundingClientRect().top) {
      myEmitter.emit('UPDATE_ACTIVE_SECTION_ONSCROLL', 'helpful-resources')
    }
  }

  componentDidMount() {
    winHeight = document.documentElement.clientHeight
    elHome = document.getElementById('helpful-resources')
    elHeader = document.getElementById('base-header')
    window.addEventListener('scroll', this.scrollListener)
  }

  componentWillMount() {
    ACTIVE_MENU_ON_SCROLL = myEmitter.addListener(
      `UPDATE_ACTIVE_SECTION_ONSCROLL`,
      data => {
        this.setState({
          activesection: data,
        })
      }
    )
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.scrollListener)
    ACTIVE_MENU_ON_SCROLL && ACTIVE_MENU_ON_SCROLL.remove()
  }

  render() {
    return (
      <Layout className="get-in-touch hcp">
        <Row>
          <Row className="content-block" id="helpful-resources">
            <Col xs={12}>
              <h1>
                Access helpful resources for <nobr>your patients</nobr>
              </h1>
              <p>
                Assertio Therapeutics is dedicated to supporting you and your patients with helpful resources related to ZIPSOR<sup>&reg;</sup> (diclofenac potassium).
              </p>
            </Col>
          </Row>
          <Row className="content-block">
          <Col xs={12} md={4}>
              <AnchorLink href="#scripts-rx">
                <img
                  src={imgNowAvailable}
                  className="content-block-img"
                  alt="Now available at Scripts Rx"
                />
              </AnchorLink>
            </Col>
            <Col xs={12} md={4}>
              <AnchorLink href="#download-copay-card">
                <img
                  src={imgDownloadCopay}
                  className="content-block-img"
                  alt="Download copay card"
                />
              </AnchorLink>
            </Col>
            <Col xs={12} md={4}>
              <AnchorLink href="#request-a-rep">
                <img
                  src={imgRequestRep}
                  className="content-block-img"
                  alt="Request a representative for samples"
                />
              </AnchorLink>
            </Col>
          </Row>
          <Row className="content-block" id="prescribe-to-scripts-rx">
            <Col xs={12}>
              <section id="scripts-rx">
                <div className="container-prescribe-to-scripts-rx">

                  <div className="headliner">
                  <Row>
                  < Col xs={8}>
                  <h1>$0 Copay</h1>
                  <h3>for commercially eligible patients (non-government) when prescribed through Scripts Rx*</h3>
                  </Col>
                    <Col xs={4}><img
                    src={imgCopayBox}
                    className="content-block-img"
                    alt="Pay as little as $0 for commercially eligible patients (non-government)"
                  /></Col>
                </Row>

                  </div>
                  <h3>Prescribe to Scripts Rx for no copay card required</h3>
                  <p>
                    <span className="text-bold">
                      Scripts Rx (formerly Mall Pharmacy)
                    </span>
                    <br />
                    1333 W. Belmont Ave., Suite 320
                    <br />
                    Chicago, IL 60657
                    <br />
                    <span className="text-bold">NCPDP/NABP:</span> 1468481
                    <br />
                    <span className="text-bold">NPI:</span> 110 498 2230
                    <br />
                    <span className="text-bold">Questions, call:</span> (844)
                    977-7749
                    <br />
                    <span className="text-bold">Fax:</span> (844) 794-7275
                    <br />
                  </p>
                </div>
                <h3 className="h2 text-purple">
                  For retail pharmacies, download Savings Card below: 
                </h3>
                <div style={{textAlign: 'left'}} id="download-copay-card"><a href='/pdf/saving-card.pdf' target="_blank" rel="noopener noreferrer" className="btn-card">GET YOUR SAVINGS CARD</a></div>
                <p className="footnotes">*Terms and conditions may apply.</p>
                <hr></hr>
              </section>
            </Col>
          </Row>
          <Row
            id="request-samples"
            className="content-block"
            id="request-a-rep-form"
          >
            <Col xs={12}>
              <section id="request-a-rep">
                <h3 className="h2 text-purple">
                  Request a ZIPSOR<sup>&reg;</sup> (diclofenac potassium) sales
                  representative
                </h3>
                <p>
                  This is intended for US healthcare providers only. Please
                  enter your contact information below.
                </p>
                <p>*All required fields are marked with an asterisk.</p>
                <RequestRepForm />
              </section>
            </Col>
          </Row>
        </Row>
        <PageIndicator
          theme={
            ['home', 'why-an-nsaid', 'zipsor-copay'].includes(
              this.state.activesection
            )
              ? 'light'
              : 'dark'
          }
        />
      </Layout>
    )
  }
}

export default IndexPage
