export default function validate(values) {
  let errors = {}
  if (!values.first_name) {
    errors.first_name = 'First Name is required.'
  }
  if (!values.last_name) {
    errors.last_name = 'Last Name is required.'
  }
  if (!values.practice_name) {
    errors.practice_name = 'Practice Name is required.'
  }
  if (!values.state) {
    errors.state = 'State is required.'
  }
  if (!values.email) {
    errors.email = 'Email Address is required.'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid.'
  }
  return errors
}
