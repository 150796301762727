/**
 *
 * HCP Get in Touch Page indicator component
 *
 */

import React from 'react'
import myEmitter from '../../../lib/emitter'
import Indicator from './Indicator'
import '../index.scss'

let ACTIVE_MENU_ON_SCROLL = null; //onscroll

class PageIndicator extends React.Component{
    state = {
        activesection: "helpful-resources"
    }
    componentWillMount(){
        ACTIVE_MENU_ON_SCROLL = myEmitter.addListener(`UPDATE_ACTIVE_SECTION_ONSCROLL`, (data) => {
            this.setState({
                activesection: data
            })
          });
    }
    componentWillUnmount() {
        ACTIVE_MENU_ON_SCROLL && ACTIVE_MENU_ON_SCROLL.remove();
    }

    render(){
        return (
            <div className={`list-indicators ${this.props.theme}`}>
              <Indicator href="#helpful-resources" caption="Helpful Resources" status={this.state.activesection==="helpful-resources" && 'active'}/>
              <Indicator href="#download-copay-card" caption="Download Copay Card" status={this.state.activesection==="download-copay-card" && 'active'}/>
              <Indicator href="#prescribe-to-scripts-rx" caption="Prescribe to Scripts Rx" status={this.state.activesection==="subscribe-to-scripts-rx" && 'active'}/>
              <Indicator href="#request-a-rep-form" caption="Request a Rep" status={this.state.activesection==="request-a-rep-form" && 'active'}/>
            </div>
            )
    }

}

export default PageIndicator